<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Communications</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Control which automated communications are sent to clients booked onto this service.
      </p>
      <div class="d-block">
        <div class="mb-2">
          <toggle-button v-model="service.send_reminder_email"
                         class="my-auto me-2"
                         color="#03006b"
                         :height=25
                         :labels="{checked: 'On', unchecked: 'Off'}" />
          <label class="custom-control-label">
            Send Reminder Emails
            <i class="far fa-info-circle text-info ms-1 ms-1"
               v-tooltip:right="'Reminder emails are sent leading up to a service booking taking place'"
            ></i>
          </label>
        </div>
      </div>
      <div class="d-block">
        <div class="mb-2">
          <toggle-button v-model="service.send_follow_up_email"
                         class="my-auto me-2"
                         sync
                         color="#03006b"
                         :height=25
                         :labels="{checked: 'On', unchecked: 'Off'}" />
          <label class="custom-control-label">
            Send Follow-up Email
            <i class="far fa-info-circle text-info ms-2 ms-2"
               v-tooltip:right="'Follow up emails are sent 24 hours after a service booking takes place'"
            ></i>
          </label>
        </div>
        <div v-if="service.send_follow_up_email" class="mt-1 mb-3">
          <select class="form-control"
                  v-model="service.follow_up_email_template_id"
                  required>
            <option :value="null">Select an Email Template (Type: Appointment)</option>
            <option v-for="template in emailTemplates.filter(function(t) { return t.type === 'appointment'})"
                    :value="template.id">{{template.title}}</option>
          </select>
        </div>
      </div>
      <div class="d-block">
        <div class="mb-2">
          <toggle-button v-model="service.send_payment_reminder_email"
                         class="my-auto me-2"
                         sync
                         color="#03006b"
                         :height=25
                         :labels="{checked: 'On', unchecked: 'Off'}" />
          <label class="custom-control-label">
            Send Payment Reminder Email
          </label>
        </div>
        <div
            v-if="service.send_payment_reminder_email"
            class="mt-1 mb-3"
        >
          <div class="form-group">
            <input type="number"
                   class="form-control d-inline me-1 me-1"
                   v-model="service.payment_reminder_days"
                   style="width: 75px"
                   min="1"
            />
            days before service takes place
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Communications",
  props: ["value"],
  data() {
    return {
      service: this.value,
      emailTemplates: [],
    }
  },
  watch: {
    service() {
      this.$emit("input", this.service)
    },
    value() {
      this.service = this.value;
    }
  },
  methods: {
    fetchEmailTemplates() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/email-templates/api").then(({ data }) => {
        this.loading = true;
        this.emailTemplates = data;
        this.loading = false;
      });
    },
  },
  created() {
    this.fetchEmailTemplates();
  }
}
</script>

<style scoped>

</style>