<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Service Availability</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Specify when this service is available for bookings. Availability can differ between delivery methods and can also be specified for each practitioner within your clinic.
      </p>
      <div class="row">
        <div class="col">
          <div class="d-flex mb-2">
            <toggle-button v-model="service.booking_options.allow_standard"
                           class="my-auto me-2"
                           color="#03006b"
                           :width=100
                           :height=25
                           :labels="{checked: 'In-Person', unchecked: 'In-Person'}" />
            <div class="ms-auto ms-auto my-auto">
              <WeekSchedulePresetSelector v-model="service.booking_options.availability_in_person"
                                          :selected-user="service.user"
                                          class="form-control-sm"/>
            </div>
          </div>
          <div class="card bg-white">
            <div class="card-body pt-2">
              <WeekScheduleSelect v-model="service.booking_options.availability_in_person"
                                  :disabled="!service.booking_options.allow_standard"/>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="d-flex mb-2">
            <toggle-button v-model="service.booking_options.allow_video"
                           class="my-auto me-2"
                           color="#03006b"
                           :width=100
                           :height=25
                           :labels="{checked: 'Video', unchecked: 'Video'}" />
            <div class="ms-auto ms-auto my-auto">
              <WeekSchedulePresetSelector v-model="service.booking_options.availability_video"
                                          :selected-user="service.user"
                                          class="form-control-sm"/>
            </div>
          </div>
          <div class="card bg-white">
            <div class="card-body pt-2">
              <WeekScheduleSelect v-model="service.booking_options.availability_video"
                                  :disabled="!service.booking_options.allow_video"/>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="d-flex mb-2">
            <toggle-button v-model="service.booking_options.allow_phone"
                           class="my-auto me-2"
                           color="#03006b"
                           :width=100
                           :height=25
                           :labels="{checked: 'Phone', unchecked: 'Phone'}" />
            <div class="ms-auto ms-auto my-auto">
              <WeekSchedulePresetSelector v-model="service.booking_options.availability_phone"
                                          :selected-user="service.user"
                                          class="form-control-sm"/>
            </div>
          </div>
          <div class="card bg-white">
            <div class="card-body pt-2">
              <WeekScheduleSelect v-model="service.booking_options.availability_phone"
                                  :disabled="!service.booking_options.allow_phone"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WeekSchedulePresetSelector from "../../../../../components/working-hours/WeekSchedulePresetSelector";
import WeekScheduleSelect from "../../../../../components/working-hours/WeekScheduleSelect";
export default {
  name: "Availability",
  components: {WeekScheduleSelect, WeekSchedulePresetSelector},
  props: ["value"],
  data() {
    return {
      service: this.value,
    }
  },
  computed: {
    allowStandard() {
      return this.service.booking_options.allow_standard;
    },
    allowVideo() {
      return this.service.booking_options.allow_video;
    },
    allowPhone() {
      return this.service.booking_options.allow_phone;
    },
    defaultSchedule() {
      return {
        "monday": [],
        "tuesday": [],
        "wednesday": [],
        "thursday": [],
        "friday": [],
        "saturday": [],
        "sunday": []
      };
    }
  },
  watch: {
    service: {
      handler: function(val) {
        this.$emit("input", this.service)
      },
      deep: true
    },
    value() {
      this.service = this.value;
    },
    allowStandard() {
      if (!this.allowStandard) {
        this.service.booking_options.availability_in_person = this.defaultSchedule;
      }
    },
    allowVideo() {
      if (!this.allowVideo) {
        this.service.booking_options.availability_video = this.defaultSchedule;
      }
    },
    allowPhone() {
      if (!this.allowPhone) {
        this.service.booking_options.availability_phone = this.defaultSchedule;
      }
    }
  }
}
</script>

<style scoped>

</style>