<template>
  <div class="d-flex w-100">
    <div class="my-auto text-nowrap">
      {{packageService.name}}
    </div>

    <button class="btn btn-sm btn-light text-nowrap ms-auto ms-auto ms-auto my-auto"
            type="button"
            data-toggle="collapse"
            :data-target="'#collapse_' + packageService.id"
            aria-expanded="true"
            :aria-controls="'collapse_' + packageService.id">
      <i class="fa fa-cog me-1 me-1"></i>
      Configure
    </button>
    <button class="btn btn-sm btn-danger text-white ms-2 ms-2 my-auto"
            @click="removePackageService()"
            type="button">
      <i class="fa fa-times-hexagon"></i>
    </button>
  </div>

</template>

<script>
export default {
  name: "PackageServiceListItem",
  props: ["value"],
  data() {
    return {
      packageService: this.value,
    }
  },
  methods: {
    removePackageService() {
      this.$emit("removed");
    },
  },
  watch: {
    service: {
      handler: function(val) {
        this.$emit("input", this.service);
      },
      deep: true
    }
  },
  components: {
  }
}
</script>

<style scoped>

</style>