<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Practitioners</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Specify which practitioners within your clinic are offering this service.
        You can choose to override service parameters for each practitioner who offers it.
      </p>
      <div v-if="!showAdd && (!practitionerServices || practitionerServices.length === 0)">
        <div class="row">
          <div class="col text-center">
            <p class="mb-2">No practitioners are currently offering this service.</p>
            <select-practitioner :users="assignableUsers" @selected="assignUserToService"></select-practitioner>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="accordion shadow-none mb-3" id="practitionerOfferingsAccordion">
          <div class="card mb-2 shadow-sm" v-for="(s, index) in practitionerServices" :key="s.user_id">
            <div class="card-header bg-white" :id="'heading_' + s.id">
              <PractitionerOfferingListItem v-model="service.practitioner_services[index]"
                                            :clinic-service="!packageMode ? service : null"
                                            @removed="removePractitionerService(s)" />
            </div>

            <div :id="'collapse_' + s.id"
                 class="collapse"
                 :aria-lheading_="'heading_' + s.id"
                 data-parent="#practitionerOfferingsAccordion">
              <div class="card-body">
                <PractitionerOfferingConfiguration
                    v-model="service.practitioner_services[index]"
                    :package-mode="packageMode"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-right text-end">
            <select-practitioner :users="assignableUsers"
                                 @open="doShowAdd"
                                 @selected="assignUserToService"></select-practitioner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectPractitioner from "../SelectPractitioner";
import PractitionerOfferingConfiguration from "./PractitionerOfferingConfiguration";
import PractitionerOfferingListItem from "./PractitionerOfferingListItem";
export default {
  name: "PractitionerOfferings",
  props: ["value", "packageMode"],
  data() {
    return {
      showAdd: false,
      service: this.value,
      assignableUsers: [],
    }
  },
  computed: {
    practitionerServices() {
      return this.service.practitioner_services;
    }
  },
  methods: {
    doShowAdd() {
      this.showAdd = true;
    },
    fetchAssignableUsers() {
      return this.$axios.get(process.env.VUE_APP_API_URL + "/settings/services/fetch-assignable-users").then(
          ({data}) => {
            console.log(Object.values(data));
            this.assignableUsers = Object.values(data).filter((u) => {
              return this.service.practitioner_services.filter((ps) => ps.user_id === u.id).length === 0
            });
          }
      );
    },
    assignUserToService(data) {
      console.log(data);
      let mergeData = {
        id: null,
        user_id: data.id,
        user: data,
        clinic_service_id: this.service.id,
        practitioner_services: [],
        instalments: this.service.instalments ? this.service.instalments.map(i => Object.assign({}, i, {id: null})) : [],
        booking_options: Object.assign({}, _.cloneDeep(this.service.booking_options), {id: null})
      }

      this.service.practitioner_services = this.service.practitioner_services ?? [];

      this.service.practitioner_services.push(
          {
            ...this.service,
            ...mergeData
          }
      )
      this.fetchAssignableUsers();
    },
    removePractitionerService(s) {
      const confRes = confirm(
          "Are you sure you wish to remove this practitioner from this service?"
      );
      if (!confRes) {
        return;
      }
      this.service.practitioner_services = this.service.practitioner_services.filter((item) => item.id !== s.id);
      this.fetchAssignableUsers();
    }
  },
  watch: {
    service: {
      handler: function(val) {
        this.$emit("input", this.service);
      },
      deep: true
    }
  },
  created() {
    this.fetchAssignableUsers();
  },
  components: {
    PractitionerOfferingListItem,
    PractitionerOfferingConfiguration,
    SelectPractitioner
  }
}
</script>

<style scoped>

</style>