<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Booking</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Specify service duration, cooldown and notice periods.
      </p>
      <div class="row">
        <div :class="lg ? 'col-3' : 'col-6'">
          <label for class="d-flex w-100">
            Duration in mins (eg. 30)
            <i class="far fa-info-circle text-info ms-auto ms-auto my-auto"
               v-tooltip:right="'Specifies length of service events in calendar'"
            ></i>
          </label>
          <input type="number" class="form-control mb-3" v-model="service.booking_options.duration" />
        </div>
        <div :class="lg ? 'col-3' : 'col-6'">
          <label for class="d-flex w-100">
            Cooldown in mins (eg. 30)
            <i class="far fa-info-circle text-info ms-auto ms-auto my-auto"
               v-tooltip:right="'Cooldown specifies the time in minutes to block availability after a service takes place.'"
            ></i>
          </label>
          <input type="number" class="form-control mb-3" v-model="service.booking_options.cooldown_mins" />
        </div>
        <div :class="lg ? 'col-3' : 'col-6'">
          <label for class="d-flex w-100">
            Min days in advance (eg. 1)
            <i class="far fa-info-circle text-info ms-auto ms-auto my-auto"
               v-tooltip:right="'Minimum number of days from now until a booking can be requested.'"
            ></i>
          </label>
          <input type="number" class="form-control" v-model="service.booking_options.min_days_notice" />
        </div>
        <div :class="lg ? 'col-3' : 'col-6'">
          <label for class="d-flex w-100">
            Max days in advance (eg. 30)
            <i class="far fa-info-circle text-info ms-auto ms-auto my-auto"
               v-tooltip:right="'Maximum number of days into the future in which a booking can be requested.'"
            ></i>
          </label>
          <input type="number" class="form-control" v-model="service.booking_options.max_days_notice" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingOptions",
  props: ["value", "lg"],
  data() {
    return {
      service: this.value
    }
  },
  watch: {
    service() {
      this.$emit("input", this.service)
    },
    value() {
      this.service = this.value;
    }
  }
}
</script>

<style scoped>

</style>