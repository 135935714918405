<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Client Booking</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Specify available client booking channels.
      </p>
      <div class="row mb-3">
        <div class="col">
          <toggle-button v-model="service.booking_options.client_booking_allowed"
                         class="my-auto me-2 me-2 me-2"
                         color="#03006b"
                         :sync="true"
                         :width=100
                         :height=25
                         :labels="{checked: 'Enabled', unchecked: 'Disabled'}" />
          <label class="custom-control-label">
            Client Booking
            <i class="far fa-info-circle text-info ms-1 ms-1"
               v-tooltip:right="'Allow clients to view and book this service'"
            ></i>
          </label>
        </div>
      </div>

      <div class="row" v-if="service.booking_options.client_booking_allowed">
        <div class="col">
          <div class="card cursor-pointer"
               :class="service.booking_options.client_booking_allowed_portal ? 'bg-primary text-white' : 'bg-light text-dark'"
               @click="service.booking_options.client_booking_allowed_portal = !service.booking_options.client_booking_allowed_portal">
            <h5 v-if="service.booking_options.client_booking_allowed_portal"
                style="position:absolute; left: 7px; top: 6px;">
              <i class="far fa-check-circle text-white" />
            </h5>
            <h5 v-else
                style="position:absolute; left: 7px; top: 6px;">
              <i class="far fa-times-circle" />
            </h5>
            <div class="card-body text-center py-2">
              <h3 class="mb-1"><i class="far fa-user" /></h3>
              <span class="font-italic">via Client Portal</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card cursor-pointer"
               :class="service.booking_options.client_booking_allowed_snippets ? 'bg-primary text-white' : 'bg-light text-dark'"
               @click="service.booking_options.client_booking_allowed_snippets = !service.booking_options.client_booking_allowed_snippets">
            <h5 v-if="service.booking_options.client_booking_allowed_snippets"
                style="position:absolute; left: 7px; top: 6px;">
              <i class="far fa-check-circle text-white" />
            </h5>
            <h5 v-else
                style="position:absolute; left: 7px; top: 6px;">
              <i class="far fa-times-circle" />
            </h5>
            <div class="card-body text-center py-2">
              <h3 class="mb-1"><i class="far fa-code" /></h3>
              <span class="font-italic">via Website Snippets</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ClientBooking",
  props: ["value"],
  data() {
    return {
      service: this.value
    }
  },
  watch: {
    service: {
      handler: function() {
        this.$emit("input", this.service)
      },
      deep: true
    },
    clientBookingAllowedPortal() {
      if (!this.clientBookingAllowedPortal && !this.clientBookingAllowedSnippet) {
        this.service.booking_options.client_booking_allowed = false;
      }
    },
    clientBookingAllowedSnippet() {
      if (!this.clientBookingAllowedPortal && !this.clientBookingAllowedSnippet) {
        this.service.booking_options.client_booking_allowed = false;
      }
    },
    clientBookingAllowed() {
      if (this.clientBookingAllowed) {
        this.service.booking_options.client_booking_allowed_portal = true;
        this.service.booking_options.client_booking_allowed_snippets = true;
      }
    }
  },
  computed: {
    clientBookingAllowed() {
      return this.service.booking_options.client_booking_allowed;
    },
    clientBookingAllowedPortal() {
      return this.service.booking_options.client_booking_allowed_portal;
    },
    clientBookingAllowedSnippet() {
      return this.service.booking_options.client_booking_allowed_snippets;
    },
  }
}
</script>

<style scoped>

</style>