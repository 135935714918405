<template>
  <select class="form-control my-auto"
          v-model="preset"
  >
    <option v-if="loading" value="0">Loading...</option>
    <option v-if="!loading" value="0">-- Select a preset --</option>
    <option v-if="!loading" disabled>─────────────</option>
    <option v-if="!loading && user && user.clinic.business_hours" value="clinic_business_hours">
      Clinic business hours
    </option>
    <option v-if="!loading && selectedUser && selectedUser.working_hours" value="user_working_hours">
      {{selectedUser.name}}'s working hours
    </option>
    <option v-if="!loading && selectedUser && selectedUser.working_hours" disabled>─────────────</option>
    <option v-for="preset in presets" :value="preset.id">{{preset.name}}</option>
    <option v-if="!loading" value="clear">Clear</option>
    <option v-if="!loading" disabled>─────────────</option>
    <option v-if="!loading" value="manage_presets">Manage Presets</option>
  </select>
</template>

<script>
export default {
  props: ["value", "selectedUser"],
  data () {
    return {
      loading: true,
      schedule: this.value,
      preset: 0,
      presets: []
    }
  },
  methods: {
    fetchPresets() {
      this.loading = true;
      this.$axios.get(process.env.VUE_APP_API_URL + '/working-hours-presets')
      .then(({data}) => {
        this.presets = data;
        this.loading = false;
      })
    }
  },
  created () {
    this.fetchPresets()
    this.$EventBus.$on('refreshWorkingHourPresets', this.fetchPresets);
  },
  beforeDestroy() {
    this.$EventBus.$off('refreshWorkingHourPresets', this.fetchPresets);
  },
  watch: {
    preset (val) {
      if (val === 'clear') {
        this.schedule ={
          "monday": [],
          "tuesday": [],
          "wednesday": [],
          "thursday": [],
          "friday": [],
          "saturday": [],
          "sunday": []
        };
      } else if (val === 'manage_presets') {
        $("#manageWorkingHoursPresetsModal").modal("show");
        this.preset = 0;
      } else if (val === 'user_working_hours') {
        this.schedule = this.selectedUser.working_hours;
      } else if (val === 'clinic_business_hours') {
        this.schedule = this.user.clinic.business_hours;
      } else if (val > 0) {
        this.schedule = this.presets.find(item => item.id === val).preset;
      }
      this.$emit("input", this.schedule)
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  }
}
</script>