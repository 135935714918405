<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">
        Package Services
      </h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        You can build packages using your existing services. Use any combination of services to create a package.
      </p>
      <div class="row">
        <div class="col">
          <div v-if="showAdd">
            <AddServiceToPackage v-model="package"
                                 @cancel="showAdd=false"
                                 @saved="showAdd=false" />
          </div>
          <div v-else>
            <div v-if="!package.services || package.services.length === 0">
              <div class="row mb-2">
                <div class="col-6 mx-auto text-center">
                  <p>No services have been added.</p>
                </div>
              </div>
            </div>
            <div class="accordion shadow-none mb-3" id="practitionerServicesAccordion">
              <div class="card mb-2 shadow-sm" v-for="(s, index) in package.services" :key="s.id">
                <div class="card-header bg-white" :id="'heading_' + s.id">
                  <PackageServiceListItem v-model="package.services[index]"
                                          @removed="removePackageService(s)" />
                </div>

                <div :id="'collapse_' + s.id"
                     class="collapse"
                     :aria-lheading_="'heading_' + s.id"
                     data-parent="#practitionerServicesAccordion">
                  <div class="card-body">
                    <PackageServiceConfiguration
                        v-model="package.services[index]" />
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex">
              <button
                  class="btn btn-outline-primary ms-auto ms-auto"
                  :class="!package.services || package.services.length === 0 ? 'mx-auto' : 'ms-auto ms-auto'"
                  v-if="!showAdd"
                  type="button"
                  @click="showAdd = true"
              >
                <i class="far fa-plus me-1 me-1"></i>
                Add a service to this package
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PackageServiceCard from "../PackageServiceCard";
import AddServiceToPackage from "./AddServiceToPackage";
import PackageServiceListItem from "./PackageServiceListItem";
import PackageServiceConfiguration from "./PackageServiceConfiguration";
export default {
  name: "PackageServices",
  components: {PackageServiceConfiguration, PackageServiceListItem, AddServiceToPackage, PackageServiceCard},
  props: ["value"],
  data() {
    return {
      package: this.value,
      showAdd: false
    }
  },
  methods: {
    removePackageService(s) {
      const confRes = confirm(
          "Are you sure you wish to remove this service from this package?"
      );
      if (!confRes) {
        return;
      }
      this.package.services = this.package.services.filter((item) => item.id !== s.id);
    }
  },
  watch: {
    service() {
      this.$emit("input", this.package)
    },
    showAdd() {
      this.$emit("toggleShowingAddService");
    }
  }
}
</script>

<style scoped>

</style>