<template>
  <div>

    <button v-if="!showInput"
            class="btn btn-outline-primary"
            @click="doShowInput">
      <i class="far fa-plus me-1"></i>
      Add Practitioner
    </button>

    <div v-if="showInput">
      <select-user v-model="selectedUser" :predefined-users="users"/>
    </div>
  </div>
</template>

<script>
import SelectUser from "../../../../components/users/SelectUser";
export default {
  components: {SelectUser},
  props: ["users"],
  data() {
    return {
      showInput: false,
      selectedUser: this.getDropdownDefault()
    }
  },
  methods: {
    getDropdownDefault() {
      return {
        'name': 'Select a User',
        'status': 'active',
        'disabled': true
      }
    },
    doShowInput() {
      this.showInput=true;
      this.$emit("open");
    }
  },
  watch: {
    selectedUser() {
      if (this.selectedUser.id) {
        this.$emit("selected", this.selectedUser);
        this.selectedUser = this.getDropdownDefault();
        this.showInput = false;
      }
    }
  }
};
</script>