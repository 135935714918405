<template>
  <li class="list-group-item">
    <div class="row">
      <div class="col my-auto">
        {{service.name}}
      </div>
      <div class="col-2 col-auto my-auto text-end">
        <button class="btn btn-light btn-sm ms-1" @click.prevent="removeService(service)">
          <i class="fa fa-trash me-1 text-danger"></i>
        </button>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: ["service"],
  methods: {
    removeService(s) {
      this.$emit("removeService", s)
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    }
  },
};
</script>
