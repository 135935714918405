<template>
  <div class="card border-0" style="box-shadow: 0 0 0 100vmax rgba(0,0,0,.3);">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Add service to package</h5>
      <div class="row mb-3">
        <div class="col">
          <select-service v-model="service" />
        </div>
      </div>
      <div v-if="service && service.id">
        <div class="row mb-3">
          <div class="col">
            <Communications v-model="newService" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <PractitionerOfferings v-model="newService" :package-mode="true"/>
          </div>
        </div>
        <!-- SAVE / CANCEL -->
        <div class="row">
          <div class="col text-right text-end">
            <button class="btn btn-light me-2 me-2" type="button" @click="cancelAdd">Cancel</button>
            <button @click="addService" type="button" class="btn btn-primary">
              <i class="far fa-plus me-1 me-2"></i>Add {{newService.name}} to package
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col text-right text-end">
            <button class="btn btn-light me-2 me-2" type="button" @click="cancelAdd">Cancel</button>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import BookingOptions from "./BookingOptions";
import Communications from "./Communications";
import Availability from "./Availability";
import PractitionerOfferings from "./PractitionerOfferings";
import SelectService from "../../../../../components/services/SelectService";
export default {
  name: "AddServiceToPackage",
  props: ["path", "user", "value"],
  data() {
    return {
      package: this.value,
      loadingServices: true,
      services: [],
      service: null,
      newService: {}
    };
  },
  computed: {
    existing() {

    }
  },
  watch: {
    service: {
      handler: function () {
        this.newService = this.service;
        this.$emit("input", this.package);
      },
      deep: true,
    },
    package: {
      handler: function () {
        this.value = this.package;
        this.$emit("input", this.package);
      },
      deep: true,
    },
    value: {
      handler: function () {
        this.package = this.value;
      },
      deep: true,
    }
  },
  methods: {
    cancelAdd() {
      this.$emit("cancel");
    },
    fetchServices() {
      this.loadingServices = true;
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/services/api").then(({ data }) => {
        this.services = data;
        this.loadingServices = false;
      });
    },
    addService() {
      if (this.newService) {
        if (!this.package.services) {
          this.package.services = [];
        }
        this.package.services.push(this.newService);
        this.$emit("input", this.package);
        this.$emit("saved");
      }
    },
  },
  mounted() {
    this.fetchServices();
  },
  components: {
    SelectService,
    PractitionerOfferings,
    Availability,
    Communications,
    BookingOptions,
  }
};
</script>
