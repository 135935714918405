<template>
  <div class="container-fluid">
    <busy :visible="loading" />
    <div v-if="!loading">

      <div class="d-flex w-100 mb-3">
        <h4 class="my-auto fw-bold text-primary">{{package.name}}</h4>
        <button
            class="btn btn-light ms-auto ms-auto my-auto"
            @click="goBack"
        >
          <i class="far fa-arrow-left"></i>
          Back to Services & Packages
        </button>
      </div>

      <package-form v-model="package" @submit="savePackage" @cancel="goBack" />

    </div>
  </div>
</template>

<script>
import PackageForm from "../services-partials/PackageForm";
export default {
  props: ["path", "user"],
  data() {
    return {
      packageId: this.$route.params.id,
      loading: true,
      busySaving: false,
      package: null,
      unsavedChanges: false,
    };
  },
  methods: {
    fetchPackage() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/packages/api/" + this.packageId).then(({ data }) => {
        this.package = data;
        this.loading = false;
      });
    },
    savePackage() {
      if (this.package.price > 0 && this.package.price < 1){
        this.$EventBus.$emit("alert", { color: 'danger', message: 'Error: Minimum price of 1.00. Free packages are also available.'})
      } else {
        this.busySaving = true;
        this.$axios
            .post(
                process.env.VUE_APP_API_URL +
                "/settings/packages/api/" +
                this.package.id,
                this.package
            )
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$emit("updated");
              this.busySaving = false;
              setTimeout(() => {
                this.$router.push({ name: "SettingsClinicServices"});
              }, 600);
            });
      }
    },
    deletePackage() {
      var confirmed = confirm(
          "Are you sure you wish to delete this package? As the clinic owner, deleting this package will also remove it for all subscribed practitioners."
      );
      if (confirmed) {
        this.$axios
            .delete(
                process.env.VUE_APP_API_URL +
                "/settings/packages/api/" +
                this.package.id
            )
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$emit("deleted");
            });
      }
    },
    goBack() {
      this.$router.push({ name: "SettingsClinicServices" });
    },
  },
  mounted() {
    this.fetchPackage();
  },
  components: {
    PackageForm,
  }
};
</script>

<style scoped>
a:hover,a:focus{
  text-decoration: none;
  outline: none;
}
.nav-tabs{
  background-color: transparent;
  border: none;
}
.nav-tabs li a{
  color: #222;
  background: transparent;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1px;
  text-align: center;
  padding: 15px 15px 10px;
  margin: 0;
  border: none;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.nav-tabs li:last-child a{ margin-right: 0; }
.nav-tabs li a:hover,
.nav-tabs li.active a{
  color: #222;
  background: #fff;
  border: none;
}
.nav-link.active{ background-color: #ffffff; color: #222;}
.nav-link.active:before{ background-color: #030069; }
.nav-link.active:after{ background-color: #030069; }
.nav-tabs li a:before,
.nav-tabs li a:after{
  content: "";
  background-color: #d1d1d1;
  height: 7px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.nav-tabs li a:after{
  background-color: #030069;
  height: 100%;
  opacity: 0;
}
.nav-tabs li.active a:before,
.nav-tabs li a:hover:before{
  height: 100%;
  opacity: 0;
}
.nav-tabs li.active a:after,
.nav-tabs li a:hover:after{
  height: 7px;
  opacity: 1;
}
</style>
