<template>
  <validation-observer ref="form">
    <form @submit.prevent="handleSubmit">
    <!-- DETAILS & COVER IMAGE -->
      <div class="row mb-4">
        <div class="col">
          <Details v-model="package" type="package" />
        </div>
        <div class="col">
          <CoverImage v-model="package" type="package"/>
        </div>
      </div>

      <!-- PRICING & BOOKING -->
      <div class="row mb-4">
        <div class="col-6">
          <Pricing v-model="package" type="package"/>
        </div>
        <div class="col-6">
          <ClientBooking v-model="package" />
        </div>
      </div>

      <!-- PACKAGE SERVICES -->
      <div class="row mb-4">
        <div class="col">
          <PackageServices v-model="package"
                           @toggleShowingAddService="showingAddService=!showingAddService" />
        </div>
      </div>

      <!-- SAVE / CANCEL -->
      <div class="row mb-3">
        <div class="col text-right text-end">
          <button class="btn btn-light me-2 me-2"
                  type="button"
                  :disabled="showingAddService"
                  @click="cancelAdd">Cancel</button>
          <button type="submit"
                  :disabled="showingAddService"
                  class="btn btn-primary">
            <i class="far fa-save me-2 me-2"></i>Save Package
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import CoverImage from "./form-partials/CoverImage";
import Details from "./form-partials/Details";
import Pricing from "./form-partials/Pricing";
import ClientBooking from "./form-partials/ClientBooking";
import PackageServices from "./form-partials/PackageServices";
export default {
  props: ["value"],
  data() {
    return {
      loading: true,
      sendReminder: false,
      sendFollowUp: false,
      package: this.value,
      showingAddService: false,
    };
  },
  watch: {
    package() {
      this.$emit("input", this.package)
    }
  },
  methods: {
    handleSubmit() {
      if (this.package.services.length > 0) {
        this.$refs.form.validate().then(success => {

          if (!success) {
            // Scroll to error
            const errors = Object.entries(this.$refs.form.refs).map(
                ([key, value]) => ({key, value})
            ).filter(error => {
              if (!error || !error.value || !error.value.failedRules) return false;
              return Object.keys(error.value.failedRules).length > 0;
            });
            if (errors && errors.length > 0) {
              this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }
            // Dont submit the form
            return;
          }
          this.$emit("submit");
        });
      } else {
        alert('Please add at least one service to this package before saving.')
      }

    },
    cancelAdd() {
      this.$emit("cancel");
    },
  },
  components: {
    PackageServices,
    ClientBooking,
    Pricing,
    Details,
    CoverImage,
  }
};
</script>
