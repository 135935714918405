<template>
  <div class="d-flex w-100">
    <img :src="practitionerService.user.profile_pic_url"
         class="rounded-circle me-2 me-2 my-auto"
         style="height: 32px; width: 32px">
    <div class="my-auto text-nowrap">
      {{practitionerService.user.name}}
    </div>

    <!--overrides-->
    <div v-if="practitionerService && clinicService" class="my-auto ms-3 ms-3">
      <span v-if="practitionerBookingOptions.price !== clinicBookingOptions.price"
            class="badge bg-info me-2 me-2">
        Price: {{practitionerService.currency.symbol}}{{practitionerBookingOptions.price}}
      </span>
      <span v-if="Boolean(practitionerBookingOptions.allow_instalments) !== Boolean(clinicBookingOptions.allow_instalments)"
            class="badge bg-info me-2 me-2">
        Instalments: {{practitionerBookingOptions.allow_instalments ? 'Yes' : 'No'}}
      </span>
      <span v-if="practitionerBookingOptions.duration !== clinicBookingOptions.duration"
            class="badge bg-info me-2 me-2">
        Duration: {{practitionerBookingOptions.duration}} mins
      </span>
      <span v-if="practitionerBookingOptions.cooldown_mins !== clinicBookingOptions.cooldown_mins"
            class="badge bg-info me-2 me-2">
        Cooldown: {{practitionerBookingOptions.cooldown_mins}} mins
      </span>
      <span v-if="practitionerBookingOptions.min_days_notice !== clinicBookingOptions.min_days_notice"
            class="badge bg-info me-2 me-2">
        Min advance: {{practitionerBookingOptions.min_days_notice}} days
      </span>
      <span v-if="practitionerBookingOptions.max_days_notice !== clinicBookingOptions.max_days_notice"
            class="badge bg-info me-2 me-2">
        Max advance: {{practitionerBookingOptions.max_days_notice}} days
      </span>
      <span v-if="Boolean(practitionerBookingOptions.allow_standard) !== Boolean(clinicBookingOptions.allow_standard)"
            class="badge bg-info me-2 me-2">
        In-Person: {{practitionerBookingOptions.allow_standard ? 'Allowed' : 'Not Allowed'}}
      </span>
      <span v-if="Boolean(practitionerBookingOptions.allow_video) !== Boolean(clinicBookingOptions.allow_video)"
            class="badge bg-info me-2 me-2">
        Video: {{practitionerBookingOptions.allow_video ? 'Allowed' : 'Not Allowed'}}
      </span>
      <span v-if="Boolean(practitionerBookingOptions.allow_phone) !== Boolean(clinicBookingOptions.allow_phone)"
            class="badge bg-info me-2 me-2">
        Phone: {{practitionerBookingOptions.allow_phone ? 'Allowed' : 'Not Allowed'}}
      </span>
      <span v-if="practitionerBookingOptions.allow_standard && !deepEqual(practitionerBookingOptions.availability_in_person, clinicBookingOptions.availability_in_person)"
            class="badge bg-info me-2 me-2">
        Availability Override: In person
      </span>
      <span v-if="practitionerBookingOptions.allow_video && !deepEqual(practitionerBookingOptions.availability_video, clinicBookingOptions.availability_video)"
            class="badge bg-info me-2 me-2">
        Availability Override: Video
      </span>
      <span v-if="practitionerBookingOptions.allow_phone && !deepEqual(practitionerBookingOptions.availability_phone, clinicBookingOptions.availability_phone)"
            class="badge bg-info me-2 me-2">
        Availability Override: Phone
      </span>
    </div>

    <button class="btn btn-sm btn-light text-nowrap ms-auto ms-auto ms-auto my-auto"
            type="button"
            data-toggle="collapse"
            :data-target="'#collapse_' + practitionerService.id"
            aria-expanded="true"
            :aria-controls="'collapse_' + practitionerService.id">
      <i class="fa fa-cog me-1 me-1"></i>
      Configure
    </button>
    <button class="btn btn-sm btn-danger text-white ms-2 ms-2 my-auto"
            @click="removePractitionerService()"
            type="button">
      <i class="fa fa-times-hexagon"></i>
    </button>
  </div>

</template>

<script>
export default {
  name: "PractitionerOfferingListItem",
  props: ["value", "clinicService"],
  data() {
    return {
      practitionerService: this.value,
    }
  },
  computed: {
    practitionerBookingOptions() {
      return this.practitionerService.booking_options;
    },
    clinicBookingOptions() {
      return this.clinicService.booking_options;
    }
  },
  methods: {
    removePractitionerService() {
      this.$emit("removed");
    },
    deepEqual(object1, object2) {
      if (!object1 && !object2) {
        return true;
      }
      if (!object1 || !object2) {
        return false;
      }
      if (object1 instanceof String) {
        object1 = JSON.parse(object1)
      }
      if (object2 instanceof String) {
        object2 = JSON.parse(object2)
      }

      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (
            areObjects && !this.deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
          return false;
        }
      }
      return true;
    },
    isObject(object) {
      return object != null && typeof object === 'object';
    }
  },
  watch: {
    service: {
      handler: function(val) {
        this.$emit("input", this.service);
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>