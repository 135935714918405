<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Cover Image</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        (Optional) Upload a cover image for your {{type}}. This is visible in the client portal as well as the Swandoola booking website snippets.
      </p>
      <div v-if="service.cover_image">
        <img :src="service.cover_image" height="150" width="150" />
        <button class="btn btn-light btn-sm" @click="service.cover_image=null">
          <i class="far fa-trash me-1 me-1"></i>
          Remove
        </button>
      </div>
      <div v-else>
        <vue-dropzone
            ref="clientDropZone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-success="uploadCompleted"
        ></vue-dropzone>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "CoverImage",
  props: ["value", "type"],
  data() {
    return {
      service: this.value
    }
  },
  computed: {
    dropzoneOptions() {
      return {
        url: process.env.VUE_APP_API_URL + "/settings/services/upload-cover-image",
        thumbnailWidth: 150,
        headers: {
          Authorization: "Bearer " + this.token,
        },
        dictDefaultMessage:
            "<i class='far fa-upload fa-4x text-primary'></i><br><br>Click here to select a file from your computer or drag n' drop a file to begin uploading",
        maxFiles: 1,
        acceptedFiles: 'image/*',
      };
    },
    token() {
      return this.$store.getters['auth/token'];
    }
  },
  methods: {
    uploadCompleted(file, response) {
      this.service.cover_image = response.url;
    },
  },
  watch: {
    service() {
      this.$emit("input", this.service)
    }
  },
  components: {
    vueDropzone: vue2Dropzone
  }
}
</script>

<style scoped>

</style>