<template>
  <v-select :options="services"
            label="name"
            :clearable="false"
            placeholder="Select a service..."
            :disabled="disabled"
            v-model="selected">
    <template v-slot:option="option">
      {{ option.name }}
    </template>
    <template v-if='loading' #selected-option="{ profile_pic_url, name }">
      <i class="far fa-spin fa-spinner me-1" />
      Loading...
    </template>
    <template v-else #selected-option="{ profile_pic_url, name }">
      {{ name }}
    </template>
    <template #no-options="{ search, searching }">
      <span v-if="loading">Loading...</span>
      <span v-else>
        No services available.
      </span>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  props: ["value", "disabled"],
  data() {
    return {
      loading: true,
      services: [],
      selected: this.value,
    };
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selected() {
      if (this.selected && !this.selected.disabled) {
        this.$emit("input", this.selected);
      }
    },
  },
  methods: {
    isSelected(el) {
      if (this.selectedElements && this.selectedElements.length) {
        return this.selectedElements.lastIndexOf(el.id) > -1;
      }
      return this.selected && this.selected.id === el.id
    },
    fetchServices() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/services/api").then(({ data }) => {
        this.services = data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.fetchServices();
  },
  components: {
    vSelect
  }
};
</script>

<style>
.minW250 {
  min-width: 250px;
}
</style>
