<template>
  <div>
    <!-- BOOKING & PRICING -->
    <div class="row mb-3">
      <div class="col">
        <Communications v-model="packageService" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <PractitionerOfferings v-model="packageService" :package-mode="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import Communications from "./Communications";
import PractitionerOfferings from "./PractitionerOfferings";
export default {
  name: "PackageServiceConfiguration",
  props: ["value"],
  data() {
    return {
      packageService: this.value,
    }
  },
  watch: {
    service: {
      handler: function(val) {
        this.$emit("input", this.packageService);
      },
      deep: true
    }
  },
  components: {
    Communications,
    PractitionerOfferings,
  }
}
</script>

<style scoped>

</style>