<template>
  <div>
    <!-- BOOKING & PRICING -->
    <div class="row mb-3">
      <div class="col">
        <BookingOptions v-model="practitionerService" :lg="packageMode"/>
      </div>
      <div class="col" v-if="!packageMode">
        <Pricing v-model="practitionerService" />
      </div>
    </div>

    <!-- AVAILABILITY -->
    <div class="row">
      <div class="col">
        <Availability v-model="practitionerService" />
      </div>
    </div>
  </div>
</template>

<script>
import Availability from "./Availability";
import BookingOptions from "./BookingOptions";
import Pricing from "./Pricing";
export default {
  name: "PractitionerOfferingConfiguration",
  props: ["value", "packageMode"],
  data() {
    return {
      practitionerService: this.value,
    }
  },
  watch: {
    service: {
      handler: function(val) {
        this.$emit("input", this.practitionerService);
      },
      deep: true
    }
  },
  components: {
    Pricing,
    BookingOptions,
    Availability,
  }
}
</script>

<style scoped>

</style>