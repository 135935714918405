<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary text-capitalize">{{ type }} Details</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Give your {{ type }} a name and description. This is public facing to your clients.
      </p>
      <validation-provider rules="required" v-slot="{ errors }">
        <span class="small text-danger" v-if="errors[0]">
          <i class="far fa-exclamation-circle me-1" />
          {{ errors[0] }}
        </span>
        <input type="text"
               id="name"
               class="form-control mb-3"
               :class="{'is-invalid': errors[0]}"
               v-model="service.name"
               placeholder="Enter a name for your service"
        />
      </validation-provider>


      <label for="description">Service Description</label>
      <textarea
          id="description"
          class="form-control"
          v-model="service.description"
          rows="5"
          :placeholder="'A short description of the service...'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Details",
  props: ["value", "type"],
  data() {
    return {
      service: this.value
    }
  },
  watch: {
    service() {
      this.$emit("input", this.service)
    }
  }
}
</script>

<style scoped>

</style>