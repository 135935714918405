<template>
  <div class="card border-0 bg-light shadow-sm h-100">
    <div class="card-body">
      <h5 class="card-title fw-bold text-primary">Pricing</h5>
      <p class="alert alert-primary mt-1">
        <i class="far fa-info-circle me-1 me-1"></i>
        Specify pricing & optional instalment plans for your {{ type }}.
      </p>
      <div class="row mb-3">
        <div class="col">
          <label>Currency</label>
          <currency-select v-model="service.currency" class="me-2 me-2"/>
        </div>
        <div class="col">
          <label>Price</label>
          <currency-input
              id="price"
              v-model="service.booking_options.price"
              :disabled="service.booking_options.allow_instalments"
              @change="syncPriceWithInstalments"
              :currency="service.currency.name"
              locale="gb"
              class="form-control w-100 me-2 me-2"
              :min="0"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">

          <toggle-button v-model="service.booking_options.allow_instalments"
                         class="my-auto me-2"
                         color="#03006b"
                         :sync="true"
                         :height=25
                         :labels="{checked: 'On', unchecked: 'Off'}" />
          <label>
            Payment in Instalments
          </label>
        </div>
      </div>

      <div v-if="service.booking_options.allow_instalments">
        <div class="card mt-3 shadow-none">
          <div class="card-body pt-2 pb-1">
            <table class="table mb-0">
              <tbody>
              <tr v-for="(instalment, index) in service.instalments">
                <td style="vertical-align: middle">Instalment #{{index+1}}</td>
                <td>
                  <currency-input
                      v-model="instalment.total"
                      :currency="service.currency.name"
                      locale="gb"
                      class="form-control d-inline"
                      placeholder="Enter a price"
                      @change="syncPriceWithInstalments"
                      :min="0"
                      required
                  />
                </td>
                <td class="text-right text-end">
                  <button class="btn btn-outline-danger" type="button" @click="removeInstalment(index)">
                    <i class="far fa-times-circle"></i>
                    Remove
                  </button>
                </td>
              </tr>
              <tr>
                <td style="border-bottom: 0px;"></td>
                <td style="border-bottom: 0px;"></td>
                <td style="border-bottom: 0px;" class="text-right text-end">
                  <button type="button" class="btn btn-outline-primary" @click="addInstalment">
                    <i class="far fa-plus me-1 me-1"></i>
                    Add Instalment
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { CurrencyInput } from "vue-currency-input";
import CurrencySelect from "../../../../../globals/CurrencySelect";
export default {
  name: "Pricing",
  props: ["value", "type"],
  data() {
    return {
      service: this.value,
    }
  },
  methods: {
    addInstalment() {
      this.service.instalments.push({});
    },
    removeInstalment(index) {
      if (this.service.instalments.length > 1){
        this.service.instalments.splice(index, 1);
      }
      this.syncPriceWithInstalments();
    },
    syncPriceWithInstalments() {
      if (this.service.booking_options.allow_instalments){
        this.service.booking_options.price = this.service.instalments.reduce(function (total, val) {
          return total + val.total;
        }, 0)
      }
    }
  },
  computed: {
    allowInstalments() {
      return this.service.booking_options.allow_instalments;
    }
  },
  watch: {
    service() {
      this.$emit("input", this.service)
    },
    allowInstalments() {
      this.syncPriceWithInstalments();
    }
  },
  components: {
    CurrencySelect,
    CurrencyInput
  }
}
</script>

<style scoped>

</style>
